import barba from "@barba/core";
import fullpage from "fullpage.js";
import Flickity from "flickity";

document.addEventListener("DOMContentLoaded", function () {
  console.log("DOM Content Loaded");

  const topCover = document.querySelector(".transition-cover.top");
  const bottomCover = document.querySelector(".transition-cover.bottom");
  const menuButton = document.getElementById("menu-button-mobile");

  menuButton.addEventListener("click", () => {
    document.body.classList.toggle("menu-out");
  });

  function setGAOptOut() {
    var disableStr = "ga-disable-G-7ZP13JV1C8";
    document.cookie =
      disableStr + "=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/";
    window[disableStr] = true;
    alert("Google Analytics tracking wurde für diesen Browser deaktiviert.");
  }

  function checkGAOptOut() {
    var disableStr = "ga-disable-G-7ZP13JV1C8";
    if (document.cookie.indexOf(disableStr + "=true") > -1) {
      window[disableStr] = true;
    }
  }

  function initializeOptOutButton() {
    document.getElementById("ga-optout").addEventListener("click", setGAOptOut);
    checkGAOptOut();
  }

  barba.init({
    sync: false,
    transitions: [
      {
        async once() {
          topCover.style.transform = "translateY(-100%)";
          bottomCover.style.transform = "translateY(100%)";
        },
        async enter() {
          window.scrollTo(0, 0);
          topCover.style.transform = "translateY(-100%)";
          bottomCover.style.transform = "translateY(100%)";
        },
        async leave(data) {
          const done = this.async();
          topCover.style.transform = "translateY(-50%)";
          bottomCover.style.transform = "translateY(50%)";
          setTimeout(() => {
            data.next.namespace === "home" ? window.location.reload() : done();
          }, 900);
        },
        async afterEnter(data) {
          updateMenu(data.next.url.path);
          if (data.next.namespace !== "home") {
            topCover.style.transform = "translateY(-100%)";
            bottomCover.style.transform = "translateY(100%)";
          }
          // Initialize GA Opt-Out after Barba.js page transition
          initializeOptOutButton();
        },
      },
    ],
    views: [
      {
        namespace: "home",
        beforeEnter() {
          import("./js/_service_bubbles.js");
          const headervideo = document.getElementById("headervideo");

          //move Footer into fullpage.js
          const footer = document.querySelector("footer");
          document.getElementById("footer-wrapper").appendChild(footer);

          new fullpage("#fullpage", {
            navigation: true,
            resize: true,
            recordHistory: false,
            scrollingSpeed: 500,
            scrollOverflow: true, // Enable scrollOverflow
            credits: {
              enabled: false,
            },
            scrollOverflowOptions: {
              // Optional: customize scrollOverflow options here
            },
            onLeave: async function (origin, destination, direction) {
              destination.index === 0
                ? headervideo.play()
                : headervideo.pause();

              if (destination.item.id === "fp-service_bubbles") {
                try {
                  const module = await import("./js/_service_bubbles.js");
                  module.updateCircles();
                } catch (error) {
                  console.error("Failed to load updateCircles:", error);
                }
              }
            },
            afterLoad: async function (origin, destination, direction) {
              if (destination.index === 2 && direction === "down")
                footer.classList.add("show-footer");
              else if (direction === "up" && origin.index === 2)
                footer.classList.remove("show-footer");
            },
          });
          var flkty = new Flickity(document.querySelector(".projects_slider"), {
            cellAlign: "center",
            draggable: false,
            contain: false,
            pageDots: false,
            adaptiveHeight: true,
            wrapAround: true,
            setGallerySize: false,
            selectedAttraction: 0.2,
            friction: 0.8,
          });
        },
        afterEnter() {},
        afterLeave() {
          //move Footer back.
          const footer = document.querySelector("footer");
          document.body.appendChild(footer);
          if (fullpage_api) fullpage_api.destroy("all"); // Destroys the fullpage.js instance
        },
      },
      {
        namespace: "page",
        beforeEnter() {},
        afterEnter() {},
        afterLeave() {},
      },
    ],
  });

  barba.hooks.afterEnter((data) => {
    // Instagram-Skript neu laden und initialisieren
    if (typeof instgrm !== "undefined") {
      instgrm.Embeds.process();
    } else {
      var script = document.createElement("script");
      script.src = "//www.instagram.com/embed.js";
      script.async = true;
      script.onload = function () {
        instgrm.Embeds.process();
      };
      document.body.appendChild(script);
    }
  });

  barba.hooks.afterLeave(() => {
    history.scrollRestoration = "manual";
    document.body.classList.remove("menu-out");
  });

  function updateMenu(path) {
    document.querySelectorAll("#menu-main-menu .menu-item").forEach((item) => {
      item.querySelector("a").getAttribute("href") === window.location.href
        ? item.classList.add("current-menu-item", "current_page_item")
        : item.classList.remove("current-menu-item", "current_page_item");
    });
  }
  updateMenu(window.location.pathname);
});
